.p-datatable .p-datatable-thead>tr>th,
.p-datatable .p-datatable-tbody>tr>td,
.p-treetable .p-treetable-thead>tr>th,
.p-treetable .p-treetable-tbody>tr>td {
  padding: 5px 5px;
}

.p-dialog-content .p-dialog-header {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.p-tabview .p-tabview-panels {
  padding-left: 0px;
  padding-right: 0px;
}

/* Alte Overwrites */

/* Panel Header */
.p-panel-header {
  padding: 0.4rem 0.5rem !important;
  font-size: 1.3em;
  height: 32px;
}

/* Entfernen der Panel Ränder (wegen Map, Inhalt erhält falls benötigt box class */
.p-panel .p-panel-content {
  padding: 0 !important;
}

.leaflet-container {
  background: none !important;
}

.p-panel .p-panel-header {
  border: none;
  border-bottom: 1px solid #383838;
}

.p-panel .p-panel-content {
  border: none;
}

.event-list > .p-element > .p-selectbutton > .p-button {
  border: 1px solid #B22222;
  border-radius: 0px;
}

* {
  user-select: none;
}