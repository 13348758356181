html {
  font-size: 11px;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.box {
  padding: 10px;
}

.pTop {
  padding-top: 10px;
}

gridster.display-grid .gridster-column,
gridster.display-grid .gridster-row {
  display: none !important;
}

gridster .gridster-column,
gridster .gridster-row,
gridster-item,
gridster-item.gridster-item-resizing,
gridster-item.gridster-item-moving {
  -webkit-transition: none !important;
  transition: none !important;
  background: none !important;
}

gridster {
  background: none !important;
}

/* BMA */
.bma {
  width: 100% !important;
  display: list-item !important;
  background-color: #dddddd;
  border: 1px solid #dddddd;
  color: darkgray;
  pointer-events: none;
  margin-bottom: 5px;
}

.bmaActive {
  width: 100% !important;
  display: list-item !important;
  pointer-events: none;
  margin-bottom: 5px;
  -webkit-animation: blink-animation 1s infinite;
  /* Safari 4+ */
  -moz-animation: blink-animation 1s infinite;
  /* Fx 5+ */
  -o-animation: blink-animation 1s infinite;
  /* Opera 12+ */
  animation: blink-animation 1s infinite;
  /* IE 10+, Fx 29+ */
}

@keyframes blink-animation {
  0% {
    background-color: #6b0000;
    border: 1px solid #6b0000
  }

  50% {
    background-color: #b22222;
    border: 1px solid #b22222;
  }

  100% {
    background-color: #6b0000;
    border: 1px solid #6b0000
  }
}

gridster.status-gridster {
  width: 100%;
  height: 100%;
}

.call1 {
  background-color: rgb(184, 0, 0);
  border: 1px solid rgb(184, 0, 0);
  animation: softflash 5s infinite;
  color: white;
}

.call2 {
  background-color: rgb(255, 0, 0);
  border: 1px solid rgb(255, 0, 0);
  animation: flash 1s infinite;
  color: white !important;
}

.call3 {
  animation-play-state: paused;
  background-color: inherit !important;
  border: 1px solid #dadada !important;
  transition: box-shadow 0.2s !important;
}

@keyframes softflash {

  0% {
    background-color: #b80000;
    color: white;
  }

  50% {
    background-color: #fa0000;
    color: white;
  }

  100% {
    background-color: #b80000;
    color: white;
  }
}

@keyframes flash {

  0%,
  20% {
    background-color: #9c0a00;
    border: 1px solid #9c0a00;
  }

  45%,
  55% {
    background-color: #fa0000;
    border: 1px solid #fa0000;
  }

  80%,
  100% {
    background-color: #9c0a00;
    border: 1px solid #9c0a00;
  }
}

.generateEventMenu {
  text-decoration: none;
  color: #fff;
  transition: background-color .2s;
  min-width: 120px;
  text-align: center;
  box-sizing: border-box;
  line-height: 20px;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: Open Sans, Helvetica Neue, sans-serif;
}

.state0,
.state1 {
  background-color: rgb(255, 0, 0) !important;
  border: 1px solid rgb(255, 0, 0) !important;
}

.state2 {
  background-color: darkorange !important;
  border: 1px solid darkorange !important;
}

.state3 {
  background-color: green !important;
  border: 1px solid green !important;
}

.inputBox {
  padding-top: 20px;
}

.keywordFix {
  margin-top: -25px;
}

.keywordButtonFix {
  margin-left: 0.5rem;
}

.vehicleRemoved {
  filter: opacity(0.5);
}

.hospitalFull {
  background-color: #b22222 !important;
  color: #ffffff !important;
}

.hospital {
  background-color: #1ca934 !important;
  color: #ffffff !important;
}

#map {
  height: 100%;
}

.leaflet-container {
  background: initial;
}

.status0 {
  background-color: rgb(255, 0, 0) !important;
  border: 1px solid rgb(255, 0, 0) !important;
  animation: flash 1s infinite;
}

.status1 {
  background-color: lightgreen !important;
  border: 1px solid lightgreen !important;
}

.status2 {
  background-color: green !important;
  border: 1px solid green !important;
}

.status3 {
  background-color: #ffd60d low !important;
  border: 1px solid #ffd60d !important;
  color: #000 !important;
}

.status4 {
  background-color: darkorange !important;
  border: 1px solid darkorange !important;
}

.status5 {
  background-color: rgb(184, 0, 0) !important;
  border: 1px solid rgb(184, 0, 0) !important;
}

.status6 {
  background-color: gray !important;
  border: 1px solid gray !important;
}

.status7 {
  background-color: lightpink !important;
  border: 1px solid lightpink !important;
}

.status8 {
  background-color: rgb(255, 89, 116) !important;
  border: 1px solid rgb(255, 89, 116) !important;
}

.status9 {
  background-color: #d62d20 !important;
  border: 1px solid #d62d20 !important;
}

.statusC {
  background-color: rgba(255, 255, 0, 0.3) !important;
  border: 1px solid rgba(255, 255, 0, 0.3) !important;
  color: black !important;
}

@keyframes flash {

  0%,
  20% {
    background-color: #9c0a00;
    border: 1px solid #9c0a00;
  }

  45%,
  55% {
    background-color: #fa0000;
    border: 1px solid #fa0000;
  }

  80%,
  100% {
    background-color: #9c0a00;
    border: 1px solid #9c0a00;
  }
}

gridster {
  background: transparent !important;
}

.statusScroll>.ui-scrollpanel-bar-x {
  display: none;
}

.fahrzeug {
  width: 100% !important;
  display: list-item !important;
  margin-bottom: 5px !important;
}

.fahrzeuge {
  display: grid !important
}

.leftText {
  float: left;
  margin-left: 5px;
}

.rightText {
  float: right;
  background: white;
  color: black !important;
  border-radius: 5px;
  width: 15px !important;
  height: 15px !important;
  padding-left: 4.5px !important;
  padding-right: 4.5px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  margin-right: 6px !important;
  border-top-width: 5px !important;
  border-bottom-width: 2px !important;
  font-size: 11px !important;
}

.vehButton {
  padding: 0.429em 1em;
  border: 2px solid #007ad9 0.2s;
  font-size: 12px;
  transition: background-color 0.2s, box-shadow 0.2s;
}

.status0 {
  background-color: rgb(255, 0, 0) !important;
  border: 1px solid rgb(255, 0, 0) !important;
  animation: flash 1s infinite;
}

.p-button-status-0-outlined {
  color: rgb(255, 0, 0) !important;
  border: 1px solid rgb(255, 0, 0) !important;
}

.p-button-status-0 {
  background-color: rgb(255, 0, 0) !important;
  border: 1px solid rgb(255, 0, 0) !important;
}

.status1 {
  background-color: lightgreen !important;
  border: 1px solid lightgreen !important;
  color: #000 !important;
}

.p-button-status-1-outlined {
  color: green !important;
  border: 1px solid lightgreen !important;
}

.p-button-status-1 {
  background-color: lightgreen !important;
  border: 1px solid lightgreen !important;
  color: #000 !important;
}

.status2 {
  background-color: green !important;
  border: 1px solid green !important;
  color: #fff !important;
}

.p-button-status-2-outlined {
  color: green !important;
  border: 1px solid green !important;
}

.p-button-status-2 {
  background-color: green !important;
  border: 1px solid green !important;
}

.status3 {
  background-color: #ffd60d !important;
  border: 1px solid #ffd60d !important;
  color: #000 !important;
}

.p-button-status-3-outlined {
  color: #ffd60d !important;
  /* yellow */
  border: 1px solid yellow !important;
  background-color: #c1c1c1 !important;
}

.p-button-status-3 {
  background-color: yellow !important;
  border: 1px solid yellow !important;
  color: #c1c1c1 !important;
}

.status4 {
  background-color: darkorange !important;
  border: 1px solid darkorange !important;
  color: #000 !important;
}

.p-button-status-4-outlined {
  color: darkorange !important;
  border: 1px solid darkorange !important;
}

.p-button-status-4 {
  background-color: darkorange !important;
  border: 1px solid darkorange !important;
}

.status5 {
  background-color: rgb(184, 0, 0) !important;
  border: 1px solid rgb(184, 0, 0) !important;
  color: #fff !important;
}

.p-button-status-5-outlined {
  color: rgb(184, 0, 0) !important;
  border: 1px solid rgb(184, 0, 0) !important;
}

.p-button-status-5 {
  background-color: rgb(184, 0, 0) !important;
  border: 1px solid rgb(184, 0, 0) !important;
  color: white;
}

.status6 {
  background-color: gray !important;
  border: 1px solid gray !important;
  color: #fff;
}

.p-button-status-6-outlined {
  color: gray !important;
  border: 1px solid gray !important;
}

.p-button-status-6 {
  background-color: gray !important;
  border: 1px solid gray !important;
}

.status7 {
  background-color: lightpink !important;
  border: 1px solid lightpink !important;
  color: black !important;
}

.p-button-status-7-outlined {
  color: lightpink !important;
  border: 1px solid lightpink !important;
}

.p-button-status-7 {
  background-color: lightpink !important;
  border: 1px solid lightpink !important;
}

.status8 {
  background-color: rgb(255, 89, 116) !important;
  border: 1px solid rgb(255, 89, 116) !important;
  color: #fff !important;
}

.p-button-status-8-outlined {
  color: rgb(255, 89, 116) !important;
  border: 1px solid rgb(255, 89, 116) !important;
}

.p-button-status-8 {
  background-color: rgb(255, 89, 116) !important;
  border: 1px solid rgb(255, 89, 116) !important;
}

.status9 {
  background-color: #d62d20 !important;
  border: 1px solid #d62d20 !important;
}

.p-button-status-9-outlined {
  color: #d62d20 !important;
  border: 1px solid #d62d20 !important;
}

.p-button-status-9 {
  background-color: #d62d20 !important;
  border: 1px solid #d62d20 !important;
}

.statusC {
  background-color: rgba(255, 255, 0, 0.3) !important;
  border: 1px solid rgba(255, 255, 0, 0.3) !important;
  color: black !important;
}

.p-button-status-C-outlined {
  color: rgba(255, 255, 0, 0.3) !important;
  border: 1px solid rgba(255, 255, 0, 0.3) !important;
}

.p-button-status-C {
  background-color: rgba(255, 255, 0, 0.3) !important;
  border: 1px solid rgba(255, 255, 0, 0.3) !important;
  color: black !important;
}


.station9 {
  margin-left: 229px !important;
  margin-top: -114px;
}

.station16,
.station18 {
  margin-top: -114px;
}

@keyframes flash {

  0%,
  20% {
    background-color: #9c0a00;
    border: 1px solid #9c0a00;
  }

  45%,
  55% {
    background-color: #fa0000;
    border: 1px solid #fa0000;
  }

  80%,
  100% {
    background-color: #9c0a00;
    border: 1px solid #9c0a00;
  }
}

.p-button-vehicle {
  padding: 0 0 0 0 !important;
  border-radius: 0px;
  margin-top: 2px !important;

}

.p-button-vehicle-bg {
  background-color: rgba(173, 173, 173, 0.363) !important;
  color: black !important;
}

.p-button-vehicle-bg>.p-button-label {
  text-align: left;
  padding-left: 5px;
}

.p-autocomplete-token:has(div.keyword-red) {
  background-color: #b22222 !important;
}

.p-autocomplete-token:has(div.keyword-orange) {
  background-color: orange !important;
}

.p-autocomplete-token:has(div.keyword-green) {
  background-color: green !important;
}

.p-autocomplete-token:has(div.keyword-blue) {
  background-color: blue !important;
}

.p-autocomplete-token:has(div.keyword-purple) {
  background-color: purple !important;
}

.p-autocomplete-token:has(div.keyword-magenta) {
  background-color: magenta !important;
  color: black !important;
}

.p-autocomplete-token:has(div.keyword-pink) {
  background-color: pink !important;
  color: black !important;
}

.p-autocomplete-token:has(div.keyword-yellow) {
  background-color: yellow !important;
  color: black !important;
}

.toggleButtonFix {
  width: 100% !important;
  height: 100% !important;
}

.p-button-selected {
  background-color: white !important;
}

.p-button-selected2 {
  background-color: #9f9f9f !important;
}

.message {
  white-space: break-spaces;
}